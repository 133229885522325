import axios from 'axios'
import endpoints from './endpoints.json'
import { HttpError } from 'react-admin';
import moment from 'moment';
import { getS3Bucket } from './awsAccess';

axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
        // Logout the user
        //logoutUser();
        console.log("Unauthorized")
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

window.Buffer = window.Buffer || require("buffer").Buffer;

export const getThumbnailsForVideo = async(params)=>{
   // console.log("ID:"+params.id)
    const rawData = await axios.get(endpoints.backendUrl+endpoints.thumbnailEndpoint, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`},params:{videoId:params.id}})
    const mappedData = {
        id:params.id,
        data:rawData.data.map(element => ({...element, uploadDate: element.uploadDate.replace('T',' ').replace(/\..{4}/,''), activationDate:element.activationDate?.replace('T',' ').replace(/\..{4}/,''), deactivationDate:element.deactivationDate?.replace('T',' ').replace(/\..{4}/,'')}))
    }
    //console.log(`Get Result: ${JSON.stringify(mappedData)}`)
    return {data:mappedData}   
}
export const createThumbnails = async(videoId, images)=>{
    let urls = []
    try{
        urls = await uploadImages(videoId, 'thumbnails', images)
    }
    catch(error){
        console.log(error.message)
        return Promise.reject(new HttpError(
            error.message,
            404,
            {}
        ))
    }
    const mappedParams = {
        videoId:videoId,
        urls:urls,
        uploadedBy: localStorage.getItem('email')
    }
    const rawData = await axios.post(endpoints.backendUrl+endpoints.thumbnailEndpoint ,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    
    //console.log(rawData)
    const mappedData = {
        id:videoId,
        urls:urls,
        uploadedBy: localStorage.getItem('email')
    }
    return {data:mappedData}
}

export const updateThumbnails = async(params) => {
    const thumbsArray = params.data.data
    let urls = []
    if (params.data.images)
    try{
        urls = await uploadImages(params.data.id, 'thumbnails', params.data.images)
    }
    catch(error){
        console.log(error.message)
        return Promise.reject(new HttpError(
            error.message,
            404,
            {}
        ))
    }
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
    urls.forEach(url=>thumbsArray.push({url:url, active:0, uploadDate:currentTime, ctr:0, uploadedBy: localStorage.getItem('email')}))
    const mappedParams = {
        id:params.id,
        videoId:params.id,
        thumbnails:thumbsArray,
    }
    thumbsArray.forEach((thumb,index)=> thumb.priority = index+1)
    
    const rawData = await axios.put(endpoints.backendUrl+endpoints.thumbnailEndpoint ,mappedParams, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    const result = {
        id:params.id,
        videoId:params.id,
        data:thumbsArray.map(thumb=>{return {url:thumb.url, active:thumb.active, ctr:thumb.ctr, uploadedBy: thumb.uploadedBy, setActiveBy:thumb.setActiveBy, priority:thumb.priority, activationDate:thumb.activationDate?.replace(/\..{3}/,''), deactivationDate:thumb.deactivationDate?.replace(/\..{3}/,'') }})
    }
    //console.log(`Edit Result: ${JSON.stringify(result)}`)
    return {data:result}
}

export const deleteThumbnails = async(params) => {
    const data = params.previousData
    await axios.delete(endpoints.backendUrl+endpoints.thumbnailEndpoint+'/'+ params.id, {headers: {Authorization: `Bearer ${localStorage.getItem('idToken')}`}})
    return {data:data}
}

//upload image to s3 bucket
export const uploadImages = async(videoId, folder, images)=>{    

    const myBucket = await getS3Bucket()
    const urls =[]
    images = images.filter(image=>image.rawFile instanceof File)
    for(const image of images){
        let imageTitle = `${videoId}_${image.title}`
        imageTitle = imageTitle.replaceAll(' ','_')
        const fileBuffer = await readFileAsync(image.rawFile)
        const params = {
            Body: fileBuffer,
            Bucket: 'flocal',
            Key: `${folder}/${imageTitle}`,
            ContentType: image.rawFile.type
        };
        try{
        await myBucket.upload(params).promise() 
        }catch(err){
            console.log(err)
        }   
        urls.push(`https://flocal.s3-us-west-1.amazonaws.com/${folder}/${imageTitle}`)
    }
    return urls
}

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onabort = () => reject('file reading was aborted')
        reader.onerror = () => reject('file reading has failed')
      reader.onload = () => {
        resolve(reader.result);
      };   
      reader.readAsArrayBuffer(file);
    })
  }
