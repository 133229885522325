import {SimpleShowLayout, TextField, Show, ReferenceField, useShowContext, useRecordContext,useDelete, TopToolbar, ListButton, useNotify, useRefresh } from 'react-admin';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress'
import React, {useRef, useEffect,useState} from "react";
import {ExternalSnapshots} from './externalSnapshots'
import { CreateSpikeDialog } from './CreateSpikesModal';
import {Chart, LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler} from 'chart.js'
Chart.register(LineElement,LineController, LinearScale, CategoryScale, PointElement, Tooltip, Filler)
let  myChart = null

 const ExternalVideoShow = () => {
  useEffect(()=>{
    const userEmail = localStorage.getItem('email')
    window.plausible('Show+External+Video', {props: {user: userEmail}})
  },[])

        return (
            <Show title= {<Title/>}>
              <TopToolbar>
                <ListButton />
              </TopToolbar>
              <SimpleShowLayout>
                <ReferenceField source = "channel_id" reference='externalChannels' emptyText='Not found' link="show">
                  <TextField source="title"/>
                </ReferenceField>
                <Content/>
                <ViewChart/>
                <hr/>
                <h5>Snapshots</h5>
                <ExternalSnapshots/>
              </SimpleShowLayout>
            </Show>
        );
    }

const Title = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>{record.title}</span>;
}

const Content = () =>{
    const {record} = useShowContext()
    if (!record) return null;
    return <>
        <Grid container spacing={2} >
          <Grid xs={4}>
            <Grid container spacing={2} >
              <Grid xs={12}>
                <Box sx={{ flexGrow: 1 }}
                style={{
                  position: "relative",
                  paddingBottom: "30%" /* 16:9 */,
                  height:"100%",
                }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    title= "Youtube"
                    src={`https://www.youtube.com/embed/${record.videoId}`}
                    frameBorder="0"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={8}> 
            <Grid container spacing={2} >
              <Grid xs={12}>
                <b>Original Title: </b>{record.originalTitle}
              </Grid>
            </Grid>
            <Grid container spacing={1} >
              <Grid xs={4}>
                <b>Publish Date: </b> {record.publishDate}
              </Grid>
              <Grid xs={8}>
                <b>Duration: </b> {record.duration}
              </Grid>
            </Grid>
            <Grid container spacing={1} >
              <Grid xs={4}>
                <b>Views: </b> {record.views}
              </Grid>
              <Grid xs={8}>
                <b>Video Performance: </b> {record.videoPerformance}
              </Grid>
            </Grid>
            <Grid container spacing={1} >
              <Grid xs={4}>
                <b>Likes: </b> {record.likes}
              </Grid>
              <Grid xs={8}>
                <b>Views per Like: </b> {record.viewsPerLike}
              </Grid>                 
            </Grid>
            <Grid container spacing={2} >
              <Grid xs={12}>
              <AddSpikesButton/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}  sx={{marginTop:"10%"}} >
          <Grid xs={3}> 
            <b>Views First 24h: </b> {record.viewsFirst24h}
          </Grid> 
          <Grid xs={3}>
            <b>Views First Week: </b> {record.viewsFirstWeek}
          </Grid>
          <Grid xs={3}>
            <b>Views Last 24h: </b> {record.viewsLast24hSum}
          </Grid>
          <Grid xs={3}>
            <b>Views Last 2h: </b> {record.viewsLast2h}
          </Grid>
        </Grid>
        <Grid container spacing={2} >
          <Grid xs={12}>
            <b>Tags: </b> {record.tags}
          </Grid> 
        </Grid>
        {record.hasRetentionSpikes && <RetentionSpikes/>}
    </>
}

export const AddSpikesButton = ()=>{
  const {record} = useShowContext()
  if(!record) return null;
  return <CreateSpikeDialog project = {record['project']} videoId = {record['videoId']}/>
}

const RetentionSpikes = ()=>{
  const {record} = useShowContext()
  if(!record) return null;
  return <Card sx={{marginTop:"6px",padding:"3px", backgroundColor:"#FAFAFB"}}>
    <h3>Retention Spikes</h3>
    {record.retentionSpikes?.map((spike, index)=>{
      return(
      <CardContent key={index}>
        <Grid container spacing={2} alignItems="center" >
          <Grid xs={3}>
            <a href={spike.url} target="_blank">Spike at {spike.startTimeAsString}</a>
          </Grid>
          <Grid xs={3}>
            <b>Duration: </b> {spike.durationAsString}
          </Grid>
          <Grid xs={5}>
            <div dangerouslySetInnerHTML={{__html: spike.description.replaceAll("'",'')}}/>
          </Grid>
          <Grid xs={1}>
            <DeleteSpikeButton spike={spike}/>
          </Grid>
        </Grid>
      </CardContent>
      )
    })}
  </Card>
}

const DeleteSpikeButton = ({spike})=>{
  const [showNotify, setShowNotify] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const [deleteOne, { data,isLoading, error }] = useDelete(
    'retentionSpikes',
    { id: spike.id, previousData: spike }
);
  const handleDelete = () => {
    deleteOne()
    setShowNotify(true)
  }
  if(error) return notify('Retention Spike not deleted', 'error')
  if(isLoading) return <CircularProgress size={40}/>
  if(data && showNotify){
    setShowNotify(false)
    notify('Retention Spike deleted', 'info')
    refresh()
  }
  return <CloseIcon onClick={e=> handleDelete()} style={{cursor:"pointer"}}/>
} 

const ViewChart =()=>{
    const {record} = useShowContext()
    const ctx = useRef()

    useEffect(()=>{
      loadChart()
  })
  const loadChart = async ()=>{
    if (!record || ctx.current === null) return <></>;

    Tooltip.positioners.bottom = function(items, eventPosition) {      
        const chart = this.chart;
      
        return {
          x: chart.chartArea.left,
          y: chart.chartArea.bottom,
          xAlign: 'center',
          yAlign: 'bottom',
        };
      };
    let labels = []
    for (let inc=0; inc<24;inc+=2){
        const paramName = inc.toString() + "hours"
        labels.push(paramName)
    }
     
    labels = labels.slice(0, record.viewsLast24h.length) 
    const viewData = JSON.parse(record.viewsLast24h).map(view =>{
      return view.views
    })
    
    if (myChart != null) {
        myChart.destroy();
    }
    myChart = new Chart(ctx.current, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: "Views last 24 Hours",
                data: viewData,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                pointStyle: 'circle',
                pointRadius: 4,
                pointHoverRadius: 8,
                tension: 0
            }
        ]
        },
        options: {
            interaction: {
                intersect: false,
                mode: 'index',
            }
        }
    })
  }
    return <div><canvas ref={ctx} width="300" height="100" ></canvas></div>
}

export default ExternalVideoShow