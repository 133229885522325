import {Fragment,useEffect, useState} from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  Datagrid,  
  List, 
  useListContext, 
  TextField,
  Create,
  SimpleForm,
  BulkExportButton,
  SelectInput,
  TextInput,
  DeleteButton,
  Button,
  useDeleteMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Show,
  SimpleShowLayout,
  useRecordContext,
  TopToolbar,
  ListButton,
  ImageField,
  ShowButton,
  usePermissions,
  useGetList
   } from 'react-admin';



export const ChannelList = () => {
  const {permissions} = usePermissions()
  const {data,isLoading} = useGetList('projects',{})

  useEffect(()=>{
    const userEmail = localStorage.getItem('email')
    window.plausible('List+External+Channel', {props: {user: userEmail}})
  },[])

  if(isLoading) return null
  const projects = data.map(project=>({id:project.dbName,name:project.name}))
  return (
  <List filters ={getChannelFilters(projects)} filterDefaultValues={{ project:projects[0].id}} >
      <Datagrid>
        <ShowButton/>
        <Thumbnail source='url'/>
        <TextField source="title" />
        <TextField source="views" />
        <TextField source="averageViewsLast2h" />
        <TextField source="videos" />
        <TextField source="subscribers" />
        {(permissions === 'superuser' || permissions === 'manager') &&  <DeleteButton />}
      </Datagrid>
  </List>
)
}

export const ChannelCreate = () => {
  const {data,isLoading} = useGetList('projects',{})

  useEffect(()=>{
    const userEmail = localStorage.getItem('email')
    window.plausible('Create+External+Channel', {props: {user: userEmail}})
  },[])

  if(isLoading) return null
  const projects = data.map(project=>({id:project.dbName,name:project.name}))
  //console.log(projects)
  return (
  <Create>
    <SimpleForm>
      <SelectInput source ='project' choices={projects}/>
      <TextInput source='videoIds' label='One Youtube URL per line' multiline fullWidth resettable/>
    </SimpleForm>
  </Create>
)
    }

const getChannelFilters = (projects) =>(
 [
  <TextInput source="searchPhrase" label="Search" alwaysOn />,
  <SelectInput source ='project' choices={projects} alwaysOn/>
])

const ChannelBulkActionButtons = () => {
  return (
  <Fragment>
      <BulkExportButton />
      <BulkDeleteByProjectButton />
  </Fragment>
);
  }
  const BulkDeleteByProjectButton = () => {
    const {filterValues, selectedIds} = useListContext()
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const [deleteMany, { isLoading }] = useDeleteMany()
    const handleClick =  () =>{
      deleteMany(
        'externalChannels',
        {ids:selectedIds,
        meta:filterValues},
        {
            onSuccess: () => {
                refresh();
                notify('Channels deleted');
                unselectAll('externalChannels');
            },
            onFailure: error => notify('Error: Channels not deleted', 'warning'),
        }
      )}


    return (
        <Button
            label="Delete selected"
            disabled={isLoading}
            onClick={handleClick}
            sx={{color:"#D3302F", fontWeight:"bold"}}
        >
          <DeleteIcon sx={{ fontSize: "large", paddingLeft: 2, color: "#D3302F" }} />
        </Button>
    );
};

export const ExternalChannelShow = () => {
  const {permissions} = usePermissions()
  useEffect(()=>{
    const userEmail = localStorage.getItem('email')
    window.plausible('Show+External+Channel', {props: {user: userEmail}})
  },[])
  return (
      <Show title= {<Title/>}>
        <TopToolbar>
        <ListButton />
        {(permissions === 'superuser' || permissions === 'manager') &&  <DeleteButton /> }
    </TopToolbar>
          <SimpleShowLayout>
              <ImageField source='thumbnail' label=''/>
              <TextField source='lastUpdate' label='Last updated'/>
              <TextField source = 'subscribers' label = 'Subscribers'/>
              <TextField source = 'videos' label = 'Videos'/>
              <TextField source = 'averageViewsLast2h' label = 'Average Views in the last 2 hours'/>
          </SimpleShowLayout>
      </Show>
  );
}

const Title = () => {
const record = useRecordContext();
// the record can be empty while loading
if (!record) return null;
return <span>{record.title}</span>;
}

const Thumbnail = ({source}) =>{
  const record = useRecordContext()
  return record ? (
    <a href={record[source]} target='_blank' rel='noreferrer'>
      <img src={record['thumbnail']} alt ={record['title']}/>
    </a>
) : null;
}
