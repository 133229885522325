import {Fragment, useEffect} from 'react'
import { 
  Datagrid,  
  List, 
  useGetList, 
  TextField,
  BulkExportButton,
  SelectInput,
  TextInput,
  ReferenceField,
  useRecordContext,
  ShowButton
   } from 'react-admin';
import Diamond from '@mui/icons-material/Diamond';
import CheckIcon from '@mui/icons-material/Check';

export const ExternalVideoList = () => {
  useEffect(()=>{
    const userEmail = localStorage.getItem('email')
    window.plausible('List External Video', {props: {user: userEmail}})
  },[])

  const {data,isLoading} = useGetList('projects',{})
  if(isLoading) return null

  const projects = data.map(project=>({id:project.dbName,name:project.name}))

    return (
    <List  filters={getVideoFilters(projects)} >
        <Datagrid bulkActionButtons={false}>
            <ShowButton label=''/>
            <Thumbnail/>
            <Link source="title" label = 'Title'/>
            <ReferenceField source = "channel_id" reference='externalChannels' emptyText='No Data' link="">
              <ChannelLink source="url" label = 'Title'/>
            </ReferenceField>
            <TextField source='publishDate' label = 'Published' />
            <TextField source='duration' label = 'Duration' sortable={false} />
            <TextField source="views"  label = 'Views' />
            <TextField source="viewsLast2h" label = 'Last 2h'/>
            <TextField source="viewsLast24hSum" label = 'Last 24h'/>
            <TextField source="videoPerformance" label = 'Performance'/>
            <HasRetentionSpikes source="hasRetentionSpikes" label = 'Retention Spikes'/>
            <MadeForKids source="madeForKids" label = 'Made For Kids'/>
        </Datagrid>
    </List>
    )
    }
    const getVideoFilters = (projects) =>(
      [
       <TextInput source="searchPhrase" label="Search" alwaysOn />,
       <SelectInput source ='project' choices={projects} alwaysOn/>
     ])
const VideoFilters = [
  <TextInput source="searchPhrase" label="Search" alwaysOn />,
  <SelectInput source ='project' choices={[
    { id: ' ', name: 'Kids Channels' },
    { id: '_crafty', name: 'Crafty Hacks' },
    { id: '_sweedee', name: 'Sweedee' },
    { id: '_games', name: 'Teens' },
    { id: '_deals', name: 'Deals' },
    { id: '_noFace', name: 'NoFace' },
    { id: '_tool_scroll', name: 'Tool Scroll' },
    { id: '_test', name: 'Tests' }
  ] } initialValue={" "} alwaysOn/>
]

const VideoBulkActionButtons = () => {
  return (
  <Fragment>
      <BulkExportButton />
  </Fragment>
);
}
const Thumbnail = () =>{
  const record = useRecordContext()
  if(!record) return null;
  let thumbnailHighRes = record['thumbnail'].replace('default','maxresdefault')
  return (
    <a href={thumbnailHighRes} target='_blank' rel='noreferrer'>
      <img src={record['thumbnail']} alt ={record['title']} style = {{height:'6em'}}/>
    </a>
)
}
const Link = ({source}) =>{
  const record = useRecordContext()
  return record ? (
      <a href={'https://www.youtube.com/watch?v='+record['videoId']} target='_blank' rel='noreferrer'>
      {record['title']}
    </a>
) : null;
}

const ChannelLink = ({source}) =>{
  const record = useRecordContext()
  return record ? (
    <a href={record[source]} target='_blank' rel='noreferrer'>
      {record['title']}
    </a>
) : null;
}
const HasRetentionSpikes = ({source}) =>{
  const record = useRecordContext()
  return (record && record[source] === 1) ? (
    <Diamond/>
) : null;
}
const MadeForKids = ({source}) =>{
  const record = useRecordContext()
  return (record && record[source] === 1) ? (
    <CheckIcon/>
) : null;
}


    
