import {useRef, useEffect, useState }from 'react';
import{
    useNotify,
    useGetIdentity,
    useDataProvider,
    Title,
    useRefresh
}from 'react-admin';
import { SSE } from "sse";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Audio } from 'react-loader-spinner'
import endpoints from '../../admin/dataProviders/endpoints.json'



const url = endpoints.backendUrl+endpoints.chatGptEndpoint+"/streamChat"
let sse = new SSE(url, {
    method: "POST",
    headers: {"Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('idToken')}`}
});
let chat = ""
let assistant = ""
export const Chat = () => {
    const { identity, identityLoading } = useGetIdentity()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [answer, setAnswer] = useState('')
    const [loading, setLoading] = useState(false)
    const [chatId, setChatId] = useState(0)
    const [customPrompt, setCustomPrompt] = useState('')
    const ref = useRef()
    const refresh = useRefresh()

    useEffect(()=>{
        (async()=>{
            if(chatId === 0){
                await setChatId(Date.now())
            }
        })()
    },[chatId])
    
    const loadChatHistory = (historicMessages, historicText)=>{
        assistant = ""
        chat = historicText.replaceAll("\n","<br/>")
        setMessages(historicMessages)
        //console.log(JSON.stringify(historicMessages))
        setAnswer(chat)
    }
    
    const cancelRequest = (e)=>{
        e.preventDefault()
        setLoading(false)
        sse.close()
        setMessages([...messages, {role:"assistant", content:"[CANCELLED]"}])
    }
    const sendRequest = async (e)=>{
         e.preventDefault()
        setLoading(true)
        chat +=`<br/><p style="color:green">${customPrompt + newMessage}</p>`
        let prompt = [...messages, {role:"user", content:customPrompt + newMessage}]
        setMessages(prompt)
        setNewMessage('')
        sse = new SSE(url, {
            method: "POST",
            headers: {"Content-Type": "application/json", "Authorization": `Bearer ${localStorage.getItem('idToken')}`}
        });
        sse.payload = JSON.stringify({messages:prompt})
        sse._onStreamFailure = (err) => {
            console.log(err)
            notify(`Unknown Error. Please try again or, reset and start new Chat`, { type: 'warning' });
            sse.close()
            assistant = ""
            setLoading(false)
        }
        sse.addEventListener("message", (event) => {
            //console.log(event)
           if(ref.current)
            ref.current.scrollTop = ref.current.scrollHeight
            if(event.data !== "[DONE]"){
                let data
                try{
                    data = JSON.parse(event.data)
                }catch (e){
                    console.log(e)
                    setLoading(false)
                    assistant = ""
                }
                if(data){
                    //console.log(data)
                    let updatedMessages =[]
                    let response = data.choices[0].delta?.content
                    if (response){
                        assistant += response
                        response = response.replaceAll("\n","<br/>")
                        chat += response
                        setAnswer(chat)
                    }
                    if(data.choices[0].finish_reason==="stop"){
                        sse.close()
                        setLoading(false) 
                        updatedMessages = [...prompt, {role:"assistant", content:assistant}]
                        setMessages(updatedMessages)
                        assistant = ""

                    }
                    else if(data.choices[0].finish_reason==="max_tokens"){
                        sse.close()
                        setLoading(false) 
                        updatedMessages = [...prompt, {role:"assistant", content:assistant}]
                        setMessages(updatedMessages)
                        assistant = ""
                        notify(`Max Tokens spent. Please reset and start new Chat`, { type: 'warning' });
                    }
                    else if(data.choices[0].finish_reason==="length"){
                        chat += "...<br/>"
                        setAnswer(chat)
                        updatedMessages = [...prompt, {role:"assistant", content:assistant}]
                        setMessages(updatedMessages)
                        notify(`Write continue to proceed`, { type: 'success' });
                    }
                    if(updatedMessages.length>0)
                        (async() => await dataProvider.saveChatGptConversation({chatId:chatId,userId:identity.id, type: "chat", messages:updatedMessages}))()
                }
            }
            else{
                setLoading(false)
                notify(`Chat Completed`, { type: 'success' });
            }
            
        });
        sse.stream();
        //setTimeout(() => sse.close(),5000)
    }
    const reset =  ()=>{
        setMessages([])
        setAnswer('')
        chat = ""
        assistant = ""
        setChatId(0)
        setNewMessage('')
        setCustomPrompt('')
        refresh()
    }

if(identityLoading || !identity) (<div>Loading...</div>)
return(
    <>
    <Title title="Chat with ChatGpt" />
    <Box sx={{height:"100%"}}>
        <Grid container spacing={2} item sx={{height:"100%"}}>
        <Grid  xs={2}>
        <Paper sx={{height:"100%", padding:"20px 10px 0 10px"}}>
            <ChatHistory identity={identity} chatId = {chatId} loadChatHistory = {loadChatHistory} reset ={reset}/>
        </Paper>
        </Grid>
        <Grid  xs={10}>
        <Paper sx={{height:"100%"}}>
        {answer!=='' ?
        <Paper ref={ref} elevation={1} sx={{marginRight:"20px", padding:"10px", height:"85vh", overflow:"hidden",overflowY: "scroll"}}>
            <div dangerouslySetInnerHTML ={{__html:answer}}></div>
        </Paper>
        :null}
        
        <Box sx={{position:"sticky",left:"20%",top:"90vh", paddingBottom:"10px" }}>       
            <TextField multiline fullWidth source='Chat' label='Chat' onChange={e => setNewMessage(e.target.value)} value={newMessage} />
            <Stack spacing={1} direction="row" sx={{marginLeft:"20px"}}>
            <Button variant="outlined"  onClick={sendRequest} className='plausible-event-name=ChatGpt+Chat+Button+clicked'>Chat</Button>
            <Button variant="outlined"  onClick={e=> cancelRequest(e)}>Cancel</Button>
            <CustomPromptSelector customPrompt = {customPrompt} setCustomPrompt={setCustomPrompt} className='plausible-event-name=ChatGpt+Custom+Chat+Button+clicked'/>
            {answer !=='' ? <Button variant="outlined"  onClick={e=> reset()}><EditNoteIcon/></Button> : null}
            {loading ? <Audio height="30" width="30" radius="9" color="#3596F3" /> : null}        
            </Stack>
        </Box>
        </Paper>
        </Grid>
        </Grid>
    </Box>
    </>
)}

const CustomPromptSelector = ({customPrompt, setCustomPrompt}) =>{
    const dataProvider = useDataProvider()
    const { data: identity, isLoading: identityLoading } = useGetIdentity()
    const [prompts, setPrompts] = useState([])
    const [selectedPromptId, setSelectedPromptId] = useState(-1)


    useEffect(()=>{
        if(customPrompt === "" && selectedPromptId !== -1){ 
            setSelectedPromptId(-1)
            return
        }
        if(identityLoading) return;
        dataProvider.getList('prompts', { meta: { owner: identity.fullName, searchTerm:"" } }).then(({data})=>{
            setPrompts(data)
        })
    },[identity, customPrompt])

    const handleChange = (event) => {
        setSelectedPromptId(event.target.value);
        if(event.target.value === -1)
            setCustomPrompt("")
        else
            setCustomPrompt(prompts.filter((prompt)=>prompt.id === event.target.value)[0].prompt)
      };

    if(identityLoading) return
    return(
        <>
        <FormControl sx={{minWidth: 200}}>
        <InputLabel id="demo-simple-select-label">Custom Prompt</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={selectedPromptId}
          label="Custom Prompt"
          
          onChange={handleChange}
        >
            <MenuItem value={-1}> - </MenuItem>
            {prompts.map((prompt, index)=>{
                return(
                    <MenuItem key={index} value={prompt.id}>{prompt.title}</MenuItem>
                )
            }
            )}
        </Select>
        </FormControl>
        </>
    )
}

const ChatHistory =({identity, chatId, loadChatHistory, reset}) =>{
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [chatHistory, setChatHistory] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [reload, setReload] = useState(0)
    useEffect(()=>{
        if(identity){
            dataProvider.getList('chatGpt', {userId:identity.id, type: "chat", searchTerm:searchTerm}).then(({data})=>{
                setChatHistory(data)
            })
        }
    },[identity, chatId,reload, searchTerm])

    const updateSearchTerm = (text)=>{
        if(text.length>2)
            setSearchTerm(text)
        else
            setSearchTerm("")
    }

    return(
        <>
            <Button variant="outlined"  onClick={e=> reset()}><EditNoteIcon/></Button>
            <h3 style={{marginBottom:0}} >History</h3>
            <TextField id="standard-basic" label="Search" variant="standard" onChange={e => updateSearchTerm(e.target.value)} />
            {chatHistory.map((item)=>{
                return(
                    <Paper key={item.id} elevation={1} sx={{margin:"5px", padding:"5px", color:"white", backgroundColor:"#3596F3"}}>
                        <HighlightOffIcon sx={{float:"right", cursor:"pointer"}} onClick={()=>{dataProvider.delete('chatGpt', {id:item.id}).then(({data})=>{
                            if(data.error) notify(data.error.message, { type: 'warning' })
                            notify(`Chat History Deleted`, { type: 'success' });
                            setChatHistory(chatHistory.filter((item)=>item.id!==data.id))
                            setReload(reload+1)
                        })}}/>
                        <div dangerouslySetInnerHTML ={{__html:item.title}} style={{cursor:"pointer"}} onClick={()=>loadChatHistory(item.messages, item.conversation)}></div>
                        <div dangerouslySetInnerHTML ={{__html:item.date}} style={{cursor:"pointer"}} onClick={()=>loadChatHistory(item.messages, item.conversation)}></div>
                    </Paper>
                )
            }
            )}
        </>
        
    )
}